// import logo from './logo.svg';
import Game from './Game';
import './Game.css';
import './App.css';

function App() {
  return (
    <div className="App">
    <Game/>
    </div>
  );
}

export default App;
